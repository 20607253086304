import React, { useState } from 'react';
import imgLogo from '../../assets/logo-votacao.png';
import './Acesso.css'; // Importar o CSS
import BigButton from '../../Componentes/BigButton/BigButton';
import SmallButton from '../../Componentes/SmallButton/SmallButton';
import { FaArrowLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { aboutMe, login, otpValidation } from '../../services/Api';
import { localStorageHelper } from '../../utils/LocalStorageHelper';
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';

function Acesso() {
	const navigate = useNavigate();
	const location = useLocation();
	const { cpf } = location.state || '';
	const [codigo, setCodigo] = useState('');

	const showMessage = (text) => {
		Swal.fire({
			text: text,
			icon: 'error',
		});
	};

	const handleCodigoChange = (event) => {
		setCodigo(event.target.value);
	};

	const handleConfirmarAcesso = async () => {
		try {
			if (!cpf || cpf.length < 1) {
				navigate('/login');
			}
			const response = await otpValidation({
				document: cpf,
				code: codigo,
			});
			if (response.status === 200) {
				localStorageHelper.setItem('token', response.data.accessToken);
				let user = await aboutMe();
				localStorageHelper.setItem('user', JSON.stringify(user));
				navigate('/perfil');
			}
		} catch (error) {
			showMessage('Erro ao confirmar o acesso.');
		}
	};

	const handleReenviarCodigo = async () => {
		try {
			await login(cpf);
			Swal.fire({
				text: 'Código reenviado com sucesso.',
				icon: 'success',
			});
		} catch (error) {
			showMessage('Erro ao reenviar o código.');
			navigate('/login');
		}
	};

	const handleGoBack = () => {
		navigate('/login');
	};

	return (
		<div class="container text-center col-lg-8 col-md-10 col-sm-10">
			<div className="row justify-content-between px-2 mt-4">
				<div class="card card-principal col-lg-12 col-md-12 col-sm-12 shadow-lg">
					<div class="card-body col-12 justify-content-center">
						<div className="row justify-content-start mt-0 mb-0">
							<FaArrowLeft className="back-arrow-btn" onClick={handleGoBack} />
						</div>
						<div className="row justify-content-center">
							<img src={imgLogo} alt="logo-destaque" className="img-logo-destaque" />
						</div>
						<h2>Confirme o Acesso</h2>
						<p>Você receberá em seu e-mail um codigo de acesso único para acessar sua conta.</p>
						<InputMask mask="999999" value={codigo} onChange={handleCodigoChange}>
							{(inputProps) => (
								<input
									{...inputProps}
									type="text"
									id="cpf"
									className="cpf-input cpf-input-login"
									placeholder="000000"
								/>
							)}
						</InputMask>
						<div className="col-12">
							<BigButton onClick={handleConfirmarAcesso} className="acesso-button">
								ACESSAR
							</BigButton>
						</div>
						<div className="col-12">
							<SmallButton onClick={handleReenviarCodigo} className="reenviar-button">
								Enviar o código novamente.
							</SmallButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Acesso;
