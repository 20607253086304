import React, { useState } from 'react';
import imgLogo from '../../assets/logo-votacao.png';
import './Cadastro.css';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { registration } from '../../services/Api';
import BigButton from '../../Componentes/BigButton/BigButton';
import InputMask from 'react-input-mask';
import { ValidateCpf } from '../../utils/ValidateCpf';
import Swal from 'sweetalert2';

function Cadastro() {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		nome: '',
		email: '',
		celular: '',
		cpf: '',
	});

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		});
	};

	const showMessage = (text) => {
		Swal.fire({
			text: text,
			icon: 'error',
		});
	};

	const handleSubmit = async () => {
		if (formData.nome.length < 3) {
			showMessage('Nome inválido.');
			return;
		}
		if (!formData.email.includes('@')) {
			showMessage('E-mail inválido.');
			return;
		}
		if (formData.celular.length < 15) {
			showMessage('Celular inválido.');
			return;
		}
		if (formData.cpf.length < 14 || !ValidateCpf(formData.cpf)) {
			showMessage('CPF inválido.');
			return;
		}

		try {
			const response = await registration({
				name: formData.nome,
				mail: formData.email,
				cellphone: formData.celular.replaceAll(/[ -]/g, ''),
				document: formData.cpf.replaceAll(/[.-]/g, ''),
			});
			console.log('Cadastro realizado com sucesso:', response.data);
			if (response.status === 201) {
				navigate('/login');
			}
		} catch (error) {
			if (error.response.status === 409) {
				showMessage('Usuário já existe.');
				return;
			}
			if (error.response.status === 400) {
				showMessage('Usuário já encerrou votação.');
				return;
			}
			showMessage('Erro ao realizar cadastro, verifique seus dados...');
		}
	};

	const handleGoBack = () => {
		navigate('/login');
	};

	return (
		<div class="container text-center col-lg-8 col-md-10 col-sm-10">
			<div className="row justify-content-between px-2 mt-4">
				<div class="card card-principal col-lg-12 col-md-12 col-sm-12 shadow-lg">
					<div class="card-body col-12 justify-content-center">
						<div className="row justify-content-start mt-0 mb-0">
							<FaArrowLeft className="back-arrow-btn" onClick={handleGoBack} />
						</div>
						<div className="row justify-content-center">
							<img src={imgLogo} alt="logo-destaque" className="img-logo-destaque" />
						</div>
						<h2 className="">Crie a sua conta.</h2>
						<div className="mt-3">
							<input
								type="text"
								placeholder="Nome Completo"
								name="nome"
								value={formData.nome}
								onChange={handleChange}
								className="cadastro-input"
							/>
							<input
								type="email"
								placeholder="E-mail"
								name="email"
								value={formData.email}
								onChange={handleChange}
								className="cadastro-input"
							/>
							<InputMask mask="(99) 99999-9999" value={formData.celular} onChange={handleChange}>
								{(inputProps) => (
									<input
										{...inputProps}
										type="text"
										id="celular"
										className="cadastro-input"
										placeholder="Celular"
										name="celular"
									/>
								)}
							</InputMask>
							<InputMask mask="999.999.999-99" value={formData.cpf} onChange={handleChange}>
								{(inputProps) => (
									<input
										{...inputProps}
										type="text"
										id="cpf"
										name="cpf"
										className="cpf-input"
										placeholder="CPF"
									/>
								)}
							</InputMask>
						</div>
						<BigButton onClick={handleSubmit} className="login-button mb-4">
							CADASTRAR
						</BigButton>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Cadastro;
