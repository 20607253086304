import React from 'react';
import Rotas from './Routes';
import logo from './assets/logo-branco.png';
import '../src/App.css';

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
	return (
		<main>
			<div id="loader" class="backdrop">
				<img src={logo} alt="logo-associacao" />
				<div class="dots">
					<div class="dot"></div>
					<div class="dot"></div>
					<div class="dot"></div>
				</div>
			</div>

			<div className="home-container">
				<Rotas />
			</div>
		</main>
	);
}

export default App;
